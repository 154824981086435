<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import {
  Cog6ToothIcon,
  CursorArrowRaysIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/vue/24/solid'

const NuxtLink = resolveComponent('nuxt-link')

const route = useRoute()

const navigation = [
  { name: 'Dashboard', to: '/', icon: HomeIcon },
  // { name: 'Mijn duurzame plan', to: '/plannen', icon: ChartPieIcon, current: false },
  {
    name: 'Mijn woning',
    icon: DocumentDuplicateIcon,
    to: '/mijn-woning',
    children: [
      { name: 'Badkamer', to: '/mijn-woning/badkamer' },
      { name: 'Keuken', to: '/mijn-woning/keuken' },
      { name: 'Toilet', to: '/mijn-woning/toilet' },
      { name: 'Samenstellen', to: '/mijn-woning/samenstellen' },
    ],
  },
  { name: 'Mijn huishouden', to: '/huishouden', icon: UsersIcon },
  { name: 'Doelen', to: '/goals', icon: CursorArrowRaysIcon },
]

const contentNavigation = [
  { name: 'Kennisbank', to: '/artikelen', icon: DocumentTextIcon },
]

const isActiveOrChild = (to: string) => route.path.includes(to)
</script>

<template>
  <div class="flex grow flex-col gap-y-20 overflow-y-auto border-r border-gray-200 bg-white px-6">
    <div class="mb-6 flex h-16 shrink-0 items-center pt-12">
      <NuxtLink to="/" class="-translate-y-2 md:-translate-y-4">
        <BaseIcon name="ikwoonbeter_logo" class="logo [&>svg]:max-w-[200px] md:[&>svg]:max-w-[180px]" />
      </NuxtLink>
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name">
              <NuxtLink
                v-if="!item.children"
                :to="item.to"
                class="group flex gap-x-3 rounded-md p-2 text-sm  leading-6 text-gray-700" :class="[
                  route.path === item.to ? 'bg-gray-50 font-bold text-green' : 'font-semibold hover:bg-gray-50',
                ]"
              >
                <component
                  :is="item.icon" class="h-6 w-4 shrink-0 "
                  :class="[route.path === item.to ? 'text-green' : 'text-gray-700']"

                  aria-hidden="true"
                />
                {{ item.name }}
              </NuxtLink>
              <Disclosure v-else v-slot="{ open }" as="div" :default-open="isActiveOrChild(item.to)">
                <DisclosureButton
                  as="NuxtLink"
                  class="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm  leading-6 text-gray-700" :class="[
                    route.path === item.to ? 'bg-gray-50 font-bold text-green' : 'font-semibold hover:bg-gray-50',
                  ]"
                >
                  <component
                    :is="item.icon" class="h-6 w-4 shrink-0
                  "
                    :class="[route.path === item.to ? 'text-green' : 'text-gray-700']"

                    aria-hidden="true"
                  />
                  <NuxtLink :to="item.to">
                    {{ item.name }}
                  </NuxtLink>

                  <ChevronRightIcon
                    class="ml-auto size-5 shrink-0" :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400']"
                    aria-hidden="true"
                  />
                </DisclosureButton>
                <DisclosurePanel as="ul" class="mt-1 px-2">
                  <li v-for="subItem in item.children" :key="subItem.name">
                    <NuxtLink
                      :to="subItem.to"
                      class="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700" :class="[
                        route.path.includes(subItem.to) ? 'bg-gray-50 font-bold text-green' : 'hover:bg-gray-50',
                      ]"
                    >
                      {{ subItem.name }}
                    </NuxtLink>
                  </li>
                </DisclosurePanel>
              </Disclosure>
            </li>
          </ul>
        </li>
        <li class="lg:hidden">
          <div class="text-xs font-semibold leading-6 text-gray-600">
            Of bekijk...
          </div>
          <ul role="list" class="-mx-2 mt-2 space-y-1">
            <li v-for="item in contentNavigation" :key="item.to">
              <NuxtLink
                :to="item.to"
                class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700" :class="[
                  route.path === item.to ? 'bg-gray-50 text-green' : 'hover:bg-gray-50',
                ]"
              >
                <component :is="item.icon" class="h-6 w-4 shrink-0 text-gray-700" aria-hidden="true" />
                {{ item.name }}
              </NuxtLink>
            </li>
          </ul>
        </li>
        <li class="-mx-6 mt-auto">
          <NuxtLink
            to="/account"
            class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
          >
            <Cog6ToothIcon class="size-5 text-gray-700" aria-hidden="true" />
            <span aria-hidden="true">Instellingen</span>
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>
