<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline'

// define emits
const emit = defineEmits<{
  (e: 'openMobileSidebar'): void
}>()

const identity = identityStore()

const route = useRoute()

const openMobileSidebar = () => {
  emit('openMobileSidebar')
}

const menu = [
  {
    label: 'Artikelen',
    to: '/artikelen',
  },
  {
    label: 'Mijn woning',
    to: '/mijn-woning',
  },
]
</script>

<template>
  <header class="py-4">
    <div class="mx-auto flex w-full max-w-7xl items-center justify-between gap-6 px-4 xl:justify-end">
      <div class="flex items-center gap-6 xl:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="openMobileSidebar"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
        <NuxtLink to="/" class="-translate-y-2 md:-translate-y-4">
          <BaseIcon name="ikwoonbeter_logo" class="logo [&>svg]:max-w-[150px] md:[&>svg]:max-w-[200px]" />
        </NuxtLink>
      </div>
      <div class="flex items-center gap-6">
        <div v-if="identity.loggedIn" class="header-filler hidden items-center justify-center gap-6 lg:flex">
          <NuxtLink
            v-for="item in menu"
            :key="item.to"
            :to="item.to"
            class="font-sans font-bold hover:underline"
            :class="{
              'text-green': route.path.includes(item.to),
              'text-neutral': !route.path.includes(item.to),
            }"
            prefetch
          >
            {{ item.label }}
          </NuxtLink>
        </div>
        <NavigationProfileMenu v-if="identity.loggedIn" />
      </div>
    </div>
  </header>
</template>
