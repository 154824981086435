<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const sidebarMenuOpen = ref(false)

useHead({
  titleTemplate: (title?: string) => `${title} - ikwoonbeter.nl`,
})
</script>

<template>
  <div class="min-h-screen bg-gray-100">
    <div class="relative">
      <TransitionRoot as="template" :show="sidebarMenuOpen">
        <Dialog as="div" class="relative z-50 xl:hidden" @close="sidebarMenuOpen = false">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="sidebarMenuOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="size-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar in mobile version -->
                <DashboardNavSidebar @close-mobile-sidebar="sidebarMenuOpen = false" />
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <DashboardNavSidebar
        class="hidden xl:fixed xl:inset-y-0 xl:z-10 xl:flex xl:w-72 xl:flex-col"
        @close-mobile-sidebar="sidebarMenuOpen = false"
      />

      <div class="xl:pl-72">
        <BaseDevbar />
        <DashboardNavTop @open-mobile-sidebar="sidebarMenuOpen = true" />
        <main class="px-4 pb-16 sm:px-6 lg:px-8">
          <NuxtPage />
        </main>
      </div>
    </div>
    <BaseNotificationList />
    <FeedbackTrigger />
  </div>
</template>
